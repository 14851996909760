.event-history ul,
.event-history li,
.event-history li button {
    list-style-type: none;
    padding: 0;
    text-align: left;
    font-size: var(--font-size-normal);
}

.events .event-history{
    padding-top: 0;
}

.events .event-history button{
    margin-bottom: .5em;
    line-height: .8em;
}

.events .text{

}

.events .images{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.events .artist button{
    cursor: pointer;
}
