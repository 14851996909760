


.artist .text p{
    margin-top: 0;
}

.artist ul {
    list-style-type: none;
    padding: 0;
    color: #c6efea;
    font-size: 0.5em;
}

.artist ul a {
    color: #c6efea;
}
