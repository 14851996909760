.loading-screen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-size: 3px;
}
.loading-screen .loading-indicator {
    display: inline-block;
    position: relative;
    width: 80em;
    height: 80em;
}
.loading-screen .loading-indicator div {
    position: absolute;
    top: 33em;
    width: 13em;
    height: 13em;
    border-radius: 50%;
    opacity: .8;
    background: deeppink;
    box-shadow: 1em 1em 3em limegreen;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-screen .loading-indicator div:nth-child(1) {
    left: 8em;
    animation: lds-ellipsis1 0.6s infinite;
}
.loading-screen .loading-indicator div:nth-child(2) {
    left: 8em;
    animation: lds-ellipsis2 0.6s infinite;
}
.loading-screen .loading-indicator div:nth-child(3) {
    left: 32em;
    animation: lds-ellipsis2 0.6s infinite;
}
.loading-screen .loading-indicator div:nth-child(4) {
    left: 56em;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24em, 0);
    }
}
