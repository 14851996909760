
.menu {
    font-size: var(--font-size-x-large);
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-margin: 50vh 0 0 0;
}


.menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .5em .75em;
    font-family: GT Maru Mega Maxi, sans-serif;
    text-shadow: #fff 0 0 50px;
    text-align: center;
    user-select: none;
    cursor: default;
    margin: 0 auto;
    font-size: .9em;
    --color: deeppink;
    --rotate: 0deg;
    --opacity: 0.75;
    --scale: 1;
    scroll-snap-align: center;
    /*border-bottom: 1px solid lime;*/
}

.menu-item label {
    transform: rotateY(var(--rotate));
    opacity: var(--opacity);
    scale: var(--scale);
    color: var(--color);
    transition: all 0.3s ease-in-out;
    display: inline-block;
}

.menu.disabled .menu-item label {
    color: deeppink;
}

.menu-item.selected label{
    text-shadow: deeppink 0 0 50px;
    cursor: pointer;
}

.menu-item:first-child {
    margin-top: calc(50vh - .75em);
}

.menu-item:last-child {
    margin-bottom: calc(50vh - .75em);
}


@media screen and (min-width: 800px) {
    .menu {
        font-size: var(--font-size-x-large);
    }

    .menu-item {
        padding: .25em 0;
    }
}
