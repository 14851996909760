.event{
    padding-top: 2.5em;
}

.event .artists ul,
.event .artists li,
.event .artists li button {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.event ul{
    list-style-type: none;
    padding: 0;
    font-size: var(--font-size-small);
}
.event .artists li,
.event .artists li button {
    display: inline;
    padding: 0;
    color: #c6efea;
    font-size: var(--font-size-small);
    text-align: left;
}

.event .artists li:not(:last-child)::after {
    content: " / ";
}

@media screen and (min-width: 801px){
    .event .images{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: dense;
        gap: 10px;
    }
}

@media screen and (min-width: 1600px){
    .event .images{
        grid-template-columns: repeat(3, 1fr);
    }
}

.event .image img{
    max-width: 100%;
}

.event .image .original-date{
    font-family: GT Maru Mega Maxi, sans-serif;
    color: #c6efea;
    font-size: var(--font-size-x-small);
}
