
.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.gallery .image {
    margin: 0.75em 0;
    position: relative;
}

.gallery .image.has-video:before{
    --width: 100px;
    content: url("./video-play.svg");
    cursor: pointer;
    display: inline-block;
    position: absolute;
    height: var(--width);
    width: var(--width);
    text-align: center;
    font-weight: normal;
    top: calc(50% - (var(--width) / 1.5));
    right: calc(50% - (var(--width)/2));
    opacity: .5;
    vertical-align: center;
}

.video{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 20;
    background: rgb(255, 20, 147, 0.33);
}

.video video{
    max-width: 80%;
    max-height: 80%;
}

.video .close{
    color: deeppink;
}

@media screen and (min-width: 801px) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: dense;
        gap: 20px;
    }

    .gallery .image {
        margin: 0;
    }

    .image.landscape {
        grid-column-end: span 2;
    }
    .image {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
    }

    .image > picture {
        grid-row: 1 / -1;
        grid-column: 1;
    }
}

@media screen and (min-width: 1200px) {
    .gallery {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }
}

@media screen and (min-width: 1600px) {
    .gallery {
        grid-template-columns: repeat(4, 1fr);
    }
}

.gallery .image img {
    max-width: 100%;
}

.gallery .image .original-date {
    font-family: GT Maru Mega Maxi, sans-serif;
    color: #c6efea;
    font-size: var(--font-size-x-small);
}
