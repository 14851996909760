.next-event{
    text-align: center;
    color: black;
    background: url("./next-event.png") no-repeat;
    background-size: 100%;
    width: 200px;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 22;
    padding: 50px;
}

.next-event section{
    display: flex;
    flex-direction: column;
}

.next-event h1, .next-event p{
    font-size: var(--font-size-small);
}

.next-event h2, .next-event a{
    font-size: calc(var(--font-size-x-small) * .75);
    color: black;
    line-height: 1em;
    margin: 0;
}

.next-event h1, .next-event  h2{
    text-transform: uppercase;
    margin: 0;
}

.next-event p{
    margin: 0 0 .5em;
}


@media screen and (min-width: 800px) {
    .next-event{
        width: 300px;
        height: 150px;
        padding: 75px;
    }
}

@media screen and (min-width: 1200px) {
    .next-event{
        width: 440px;
        height: 220px;
        padding: 100px;
    }
}
