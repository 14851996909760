hr {
  height: 0;
  border: none;
  border-bottom: 10px dotted white;
  width: 100%;
}

.about-button{
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 999;
}

.overlay-page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;
  box-sizing: border-box;
  overflow-y: auto;
  padding-top: 4em;
  padding-bottom: 2em;
}

.overlay-page .close,
.overlay-page .up{
  background: transparent;
  border: none;
  font-family: GT Maru Mega Maxi, sans-serif;
  font-size: 80px;
  position: fixed;
  top: var(--margin-medium);
  right: var(--margin-medium);
  cursor: pointer;
  z-index: 10;
}

.overlay-page .up{
  right: calc(var(--margin-medium) * 4);
}

.overlay-page .content{
  text-shadow: none;
}

.overlay-page .text{
  padding: 0 0.5em;
}

.shimmer{
  color: transparent!important;
  animation-name: shimmer;
  animation-duration: 5s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  background: linear-gradient(90deg, #c6efea 0%, #f678db 50%, #c6efea 100%);
  background-clip: text;
  background-size: 50% 100%;
}

@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@media (min-width: 601px){
  .overlay-page{
    user-select: none;
  }

  .overlay-page .content{
    padding: .5em;
  }

  .overlay-page .text,
  .overlay-page .text a{
    padding: 0;
    -webkit-text-stroke: .25px deeppink;
  }

  .overlay-page .close,
  .overlay-page .up{
    top: var(--margin-large);
    right: var(--margin-large);
  }

  .overlay-page .up{
    right: calc(var(--margin-large) * 2);
  }
}


#canvas-container{
  width: 100vw;
  height: 100vh;
}
#logo-container{
  position: fixed;
  top: 10px;
  left: 0;
  z-index: 100;
  height: 150px;
  width: 150px;
  cursor: pointer;
}

@media (min-width: 601px){
  #logo-container{
    top: 0;
    height: 250px;
    width: 250px;
  }
}
