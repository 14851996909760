:root{
  --font-size-x-small: 20px;
  --font-size-small: 25px;
  --font-size-normal: 40px;
  --font-size-large: 45px;
  --font-size-x-large: 60px;
  --margin-medium: 20px;
  --margin-large: 50px;
}

body {
  margin: 0;
  background: #ff1dce;
  overflow: hidden;
}

body, a:link, a:visited, a:active, button {
  font-family: GT Maru Mega Maxi, sans-serif;
  color: #c6efea;
  font-size: var(--font-size-normal);
}

@media screen and (min-width: 801px){
  body{
    --font-size-x-small: 30px;
    --font-size-small: 35px;
    --font-size-normal: 50px;
    --font-size-large: 75px;
    --font-size-x-large: 90px;
  }
}

@media screen and (min-width: 1200px){
  body{
    --font-size-x-small: 40px;
    --font-size-small: 50px;
    --font-size-normal: 65px;
    --font-size-large: 75px;
    --font-size-x-large: 90px;
  }
}

h1 {
  font-size: var(--font-size-large);
  margin: .5em 0;
}

h1, h2, h3, h4, h5, h6{
  font-weight: normal;
}

ul, p{
  margin: .5em 0;
}

ul, ol{
  font-size: var(--font-size-small);
}

a:link, a:visited, a:active, button{
  background: transparent;
  border: none;
  font-size: 1em;
  cursor: pointer;
  text-shadow: inherit;
}

button{
  margin: 0;
  padding: 0;
}
