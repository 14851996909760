.about .children ul,
.about .children li,
.about .children li button {
    list-style-type: none;
    padding: 0;
    text-align: left;
    font-size: var(--font-size-normal);
}

.about .children button{
    margin-bottom: .5em;
    line-height: .8em;
}

.about .images{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.about .artist button{
    cursor: pointer;
}
